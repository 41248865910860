// Merge the brand level theme and the site level theme.
// The second object being passed to the merge function
// is the site level theme. Anything placed here will
// override the brand level theme.
export default {
  colors: {
    primary: '#006DFF',
    secondary: '#FDD746',
    tertiary: '#09F0FF',
    dark: '#000443',
    light: '#F6F7F8',
    black: '#000000',
    white: '#FFFFFF',
    warning: '#FF7928',
    error: '#FF3068',
    success: '#2DE6BC',
    background1: '#EDF9FF',
    background2: '#E4F0FF',
    background3: '#EAFFFE',
    background4: '#F0F3FF',
    background5: '#FFFCEB',
    background6: '#000443',
  },
  gradients: {
    primary: 'linear-gradient(60deg, #000443, #006dff, #09f0ff)',
    secondary: 'linear-gradient(60deg, #000443, #006dff, #09f0ff)',
    tertiary: 'linear-gradient(60deg, #000443, #006dff, #09f0ff)',
  },
  backgroundColors: {
    primary: {
      textColor: '#ffffff',
    },
    secondary: {
      textColor: '#ffffff',
    },
    tertiary: {
      textColor: '#000443',
    },
    white: {
      textColor: '#000443',
    },
    light: {
      textColor: '#000443',
    },
  },
  alternateBgColor: true,
  fonts: {
    headings: {
      family: 'PF Din Text Bold, Helvetica',
      lg: {
        weight: '700',
      },
      md: {
        weight: '700',
      },
      sm: {
        weight: '700',
      },
    },
    subheadings: {
      family: 'PF Din Text Medium, Helvetica',
      lg: {
        weight: '400',
      },
      md: {
        weight: '400',
      },
      sm: {
        weight: '400',
      },
    },
    body: {
      family: 'PF Din Text Medium, Helvetica',
      weight: '400',
    },
  },
  components: {
    Button: {
      rounded: 'min',
      variants: {
        primary: {
          color: '#ffffff',
          hoverBg: '#0853C5',
        },
        secondary: {
          color: '#000443',
          hoverBg: '#FCC623',
        },
        tertiary: {
          color: '#000443',
          hoverBg: '#00D2E5',
        },
        white: {
          hoverBg: '#102641',
        },
      },
    },
    PackageCard: {
      radius: '8px',
      backgroundColor: '#ffffff',
      color: '#000443',
      stroke: {
        width: '1px',
        style: 'solid', // CSS value
        color: '#000443',
      },
      label: {
        inside: 'true',
        backgroundColor: '#000443',
        radius: '8px',
      },
      title: {
        fontSize: '18px',
        lineHeight: '24px',
        fontWeight: 'bold',
      },
    },
    Price: {
      dollarSign: {
        weight: 'bold',
        // size: '20px',
        // position: 'top', // top, bottom (default), center
        // offsetY: '7px',
      },
      dollar: {
        weight: 'bold',
      },
      cents: {
        weight: 'bold',
        size: '20px',
        position: 'bottom', // top, bottom, center (default) // not used anywhere yet
        offsetY: '-8px',
      },
      duration: {
        position: 'bottom', // top, bottom, center (default), underCents
        size: '20px',
        offsetY: '-8px',
      },
    },
  },
  shadow: '0 0.3rem 1rem 0 rgba(0, 0, 0, 0.1)',
  radius: '8px',
}
