import { css, Global, withTheme } from '@emotion/react'

import * as React from 'react'

const GlobalCss = withTheme(() => (
  <Global
    styles={css`
      /* TODO: This radius should be added in the core, and pulled from the radius defined in the theme */
      .leshen-columns-card > div {
        border-radius: 8px;
      }

      .leshen-table-wrapper {
        max-height: 450px;
        overflow-y: scroll;
      }

      .show-desktop,
      .show-mobile {
        display: none;
      }

      .leshen-package-card {
        isolation: unset !important;
      }

      .package-label {
        z-index: -1 !important;
      }

      .leshen-phone-cta,
      .leshen-link-button {
        box-shadow: none !important;
      }

      @media (min-width: 768px) {
        .show-desktop {
          display: block;
        }
      }

      @media (max-width: 768px) {
        .show-mobile {
          display: block;
        }
      }

      .Twilio-EntryPoint {
        position: absolute !important;
        bottom: 73px !important;
        color: #000443 !important;

        svg {
          fill: #000443 !important;
        }

        @media screen and (min-width: 769px) {
          bottom: 89px !important;
        }
      }

      // Following styles necessary as "label: 'inside'" does NOT work in the theme.js
      // and these brand updates are urgent.
      .leshen-package-card {
        .package-label {
          border-radius: 24px !important;
          position: relative !important;
          top: 0 !important;
          left: 0 !important;
          margin-left: 0 !important;
          padding-bottom: 2px !important;
        }
      }
    `}
  />
))

export default GlobalCss
